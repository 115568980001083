// Vendor Libraries
import $ from "../../../../../node_modules/cash-dom/dist/cash.esm.js";
let $document = $(document);

// Primary nav handlers
$document.on(`mouseover`, `.artisan-site-header:not(.menu-open) nav > ul > .isParent`, function() {
  $(this).addClass(`open`);
});
$document.on(`mouseout`, `.artisan-site-header:not(.menu-open) nav > ul > .isParent`, function() {
  $(this).removeClass(`open`);
});

$document.on(`click`, `.artisan-site-header a + button`, function() {
  let $toggle = $(this);
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = $navItem.siblings();
  // Reset other items.
  $otherNavItems.removeClass(`open`);
  $otherNavItems.children(`button`).attr(`aria-expanded`, `false`);

  $navItem.toggleClass(`open`);
  if ($toggle.attr(`aria-expanded`) === `true`) {
    $toggle.attr(`aria-expanded`, `false`);
  } else {
    $toggle.attr(`aria-expanded`, `true`);
  }
});

$document.on(`click`, `.artisan-site-header.menu-open nav .isParent > a`, function(event) {
  const $navItem = $(this);
  const $parent = $navItem.parent();

  if (!$navItem.siblings(`ul`).length) {
    return;
  }

  event.preventDefault();

  if ($parent.hasClass(`open`)) {
    $parent.removeClass(`open`);
  } else {
    $parent.addClass(`open`).siblings().removeClass(`open`);
  }
});

$document.on(`click`, function(event) {
  const button = event.target.closest(`.artisan-site-header [aria-controls="menu"]`);
  if (!button) {
    return;
  }

  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].artisan-site-header")[0];

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  $("body")[0].classList.toggle("overflow-hidden");
});